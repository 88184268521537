import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const textStyles = {
    question: {
        fontFamily: 'Arial, sans-serif',
        textAlign: 'left',
        marginTop: '20px', // Space between the previous answer and the next question
    },
    answerContainer: {
        backgroundColor: '#f8f9fa', // Light gray background
        border: '1px solid rgb(255, 204, 180)', // Light orange border
        borderRadius: '8px', // Slightly rounded edges
        padding: '10px', // Padding inside the container
        marginTop: '0px', // Space between the question and the corresponding answer
    },
    answer: {
        fontFamily: 'Arial, sans-serif',
        textAlign: 'left',
        margin: 0, // Remove default margin
    },
    header: {
        fontFamily: 'Jancient, sans-serif',
        textAlign: 'center',
        marginBottom: '20px', // Add spacing after the header
    },
};

const About: React.FC = () => {
    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: 4, // Material-UI spacing (4 = 4 * 8px = 32px)
                marginBottom: 4,
            }}
        >
            <Box>
                {/* Page Header */}
                <Typography
                    variant="h2" // Uses theme's h2 styles
                    gutterBottom
                    sx={{ fontFamily: 'Jancient, sans-serif', textAlign: 'center' }}>

                    About
                </Typography>

                {/* First Question */}
                <Typography variant="h5" gutterBottom>
                    <strong>What is APM?</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1">
                        American Polling Machine was created to illuminate the opinions of every American. This is accomplished through daily polls covering a variety of topics.
                        We feel that the opinions expressed through traditional media, social media, entertainment, and even academia have become very polarizing. Many people, regardless of background, feel their opinions are not accurately portrayed or even known to the public.
                        APM provides a fun, entertaining, secure, and anonymous platform for Americans to safely share their honest opinions.
                    </Typography>
                </Box>

                {/* Second Question */}
                <Typography variant="h5" gutterBottom sx={{ marginTop: 3 }}>
                    <strong>Am I safe to share my true opinions?</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1">
                        Yes, absolutely. As you create a profile, you are never asked your name, date of birth, or other personal identifiers. We only ask your basic demographic information. Your identity and opinions are secure.
                    </Typography>
                </Box>

                {/* Third Question */}
                <Typography variant="h5" gutterBottom sx={{ marginTop: 3 }}>
                    <strong>How is polling data used?</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1">
                        Our dream is for many Americans to begin to share their honest opinions on a daily basis. The more opinions shared, the better our way of life will become. Poll result data will be publicly available to those who have polled. Demographic data will be provided to elected officials, companies, organizations, academic institutions, and many others. The end result will be better policies, products, offerings, and solutions, based on a much more accurate public opinion.
                        We will continue to enhance the APM experience each day. We invite you to poll daily, share your thoughts and feedback along the way. Please invite those you associate with to join the fun.
                        Let’s Poll!
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default About;
