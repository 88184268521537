import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer'; 
import PrivacyPolicy from './pages/PrivacyPolicy'; 
import Verification from './pages/Verification'; 
import {
    Typography,
    Box,
    Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import appStoreBadge from './assets/app-store-badge.svg'; 
import playStoreBadge from './assets/play-store-badge.png'; 
import './App.css';
import ResetPassword from './pages/ResetPassword';
import ApmAppBar from './components/APMAppBar';
import About from './pages/About';
import Delete from './pages/DeletePolicy';

const theme = createTheme({
    palette: {
        background: {
            default: '#FFFFFF',
            paper: '#1e90ff',
        },
        text: {
            primary: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Jancient, sans-serif',
        h1: {
            fontSize: '4rem',
            fontWeight: 700,
            color: '#1e90ff', // Explicitly set color
            '@media (max-width:600px)': {
                fontSize: '2rem',
            },
        },
        h2: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '3rem',
            fontWeight: 400,
            color: '#1e90ff', // Explicitly set color
            '@media (max-width:600px)': {
                fontSize: '2rem',
            },
        },
        h5: {
            fontFamily: 'Jancient, sans-serif',
            fontSize: '1.5rem',
            fontWeight: 100,
            color: '#1e90ff', // Explicitly set color
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        button: {
            textTransform: 'none',
            fontSize: '1rem',
            color: '#000000',
        },
    },
    
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: '#1e90ff', // Hover background color
                        color: '#ffffff', // Hover text color
                    },
                },
            },
        },
    },
});



const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Box
                    sx={{
                        height: '100vh', // Full viewport height
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative', // Ensures relative positioning for the footer
                    }}
                >
                    <ApmAppBar />
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto', // Allow scrolling for the main content
                            marginTop: '150px'
                        }}
                    >
                        <Routes>
                            <Route path="/" element={
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        bgcolor: 'background.default',
                                        padding: 10,
                                    }}
                                >
                                    <Typography variant="h1" align="center" gutterBottom sx={{ fontFamily: 'Jancient, sans-serif' }}>
                                        Where America Comes To Poll
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 2,
                                            marginTop: 1,
                                        }}
                                    >
                                        <Button
                                            component="a"
                                            href="https://apps.apple.com/us/app/american-polling-machine/id6670273172"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ padding: 0 }}
                                        >
                                            <img
                                                src={appStoreBadge}
                                                alt="Download on the App Store"
                                                style={{
                                                    height: '60px',
                                                    width: 'auto',
                                                }}
                                            />
                                        </Button>
                                        <Button
                                            component="a"
                                            href="https://play.google.com/store/apps/details?id=com.letspoll.apm"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ padding: 0 }}
                                        >
                                            <img
                                                src={playStoreBadge}
                                                alt="Get it on Google Play"
                                                style={{
                                                    height: '60px',
                                                    width: 'auto',
                                                }}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            } />
                            <Route path="about" element={<About />} />
                            <Route path="privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/verification/:status" element={<Verification />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/delete-account" element={<Delete />} />
                        </Routes>
                    </Box>
                    <Footer /> {/* Footer remains as a separate component */}
                </Box>
            </Router>
        </ThemeProvider>
    );
};

export default App;

