import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import styles from '../css/ResetPassword.module.css'; 

const ResetPassword: React.FC = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();

    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError("Passwords don't match.");
            return;
        }

        const token = searchParams.get('token');
        if (!token) {
            setError('No token provided.');
            return;
        }

        try {
            const response = await fetch('https://apmapi20240818083018.azurewebsites.net/api/user/resetpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    newPassword,
                }),
            });

            if (response.ok) {
                setSuccessMessage('Your password has been reset successfully. Please login.');
                setError('');
            } else {
                const data = await response.json();
                setError(data.message || 'Failed to reset password.');
            }
        } catch (error) {
            setError('An error occurred. Please try again later.');
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 470px)',
                paddingBottom: 10,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '500px',
                    bgcolor: '#fff',
                    padding: 3,
                    borderRadius: 2,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50%',
                }}
            >
                <Typography
                    variant="h2"
                    className={styles.title}
                    sx={{
                        marginBottom: 2,
                        color: '#1E90FF',
                        fontFamily: 'JAncient'
                    }}
                >
                    Reset Your Password
                </Typography>

                <form onSubmit={handleSubmit}  className={styles.form}>
                    {/* New Password Text Field */}
                    <Box sx={{ mb: 1, height: '50%', fontFamily:'Arial, sans-serif' }}>
                        <TextField
                            label="New Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            required
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            InputLabelProps={{
                                sx: { 
                                    color: 'dodgerblue', // Label color
                                },
                            }}
                            InputProps={{
                                sx: { 
                                    input: { fontFamily: 'Arial !important' },
                                    color: 'dodgerblue' 
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {/* Confirm Password Text Field */}
                    <Box sx={{ mb: 1, height: '50%' }}>
                        <TextField
                            label="Confirm Password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            InputLabelProps={{
                                sx: { 
                                    color: 'dodgerblue', // Label color
                                },
                            }}
                            InputProps={{
                                sx: { 
                                    input: { fontFamily: 'Arial !important' },
                                    color: 'dodgerblue'  // Apply font-family to input element
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {/* Error Message */}
                    {error && (
                        <Typography
                            variant="body1"
                            sx={{ color: 'red', mb: 2 }}
                        >
                            {error}
                        </Typography>
                    )}

                    {/* Success Message */}
                    {successMessage && (
                        <Typography
                            variant="body1"
                            sx={{ color: 'green', mb: 2 }}
                        >
                            {successMessage}
                        </Typography>
                    )}

                    {/* Submit Button */}
                    <Box>
    <Button
        sx={{
            fontFamily: 'Arial',
            fontSize: 20,
            padding: '10px 0', // Reduced padding to balance the height
            lineHeight: 5, // Ensures consistent vertical spacing for the text
            minHeight: 'auto', // Avoid enforced minimum height
            height: '40px', // Manually set the height to ensure the button size matches your design
            display: 'flex',
            alignItems: 'center', // Vertical alignment fix
            justifyContent: 'center', // Horizontal alignment
        }}
        type="submit"
        variant="contained"
        color="primary"
    >
        Reset Password
    </Button>
</Box>


                </form>
            </Box>
        </Container>
    );
};

export default ResetPassword;
