import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const textStyles = {
    question: {
        fontFamily: 'Arial, sans-serif',
        textAlign: 'left',
        marginTop: '20px', // Space between the previous answer and the next question
    },
    answerContainer: {
        backgroundColor: '#f8f9fa', // Light gray background
        border: '1px solid rgb(255, 204, 180)', // Light orange border
        borderRadius: '8px', // Slightly rounded edges
        padding: '10px', // Padding inside the container
        marginTop: '0px', // Space between the question and the corresponding answer
    },
    answer: {
        fontFamily: 'Arial, sans-serif',
        textAlign: 'left',
        margin: 0, // Remove default margin
    },
    header: {
        fontFamily: 'Jancient, sans-serif',
        textAlign: 'center',
        marginBottom: '20px', // Add spacing after the header
    },
};

const Delete: React.FC = () => {
    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: 4, // Material-UI spacing (4 = 4 * 8px = 32px)
                marginBottom: 4,
            }}
        >
            <Box>
                {/* Page Header */}
                <Typography
                    variant="h2" // Uses theme's h2 styles
                    gutterBottom
                    sx={{ fontFamily: 'Jancient, sans-serif', textAlign: 'center' }}>

                    Delete
                </Typography>

                {/* First Question */}
                <Typography variant="h5" gutterBottom>
                    <strong>How to Delete</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1">
                        If you must delete your account, the APM app allows you to do so. Just navigate to the Profile Page in the menu.  At the bottom is a link to delete your account. We hate to see you go, but thank you for trying us out.
                    </Typography>
                </Box>

            </Box>
        </Container>
    );
};

export default Delete;
