import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const textStyles = {
    question: {
        fontFamily: 'Jancient, sans-serif',
        textAlign: 'left',
        marginTop: '20px', // Space between the previous answer and the next question
    },
    answerContainer: {
        backgroundColor: '#f8f9fa', // Light gray background
        border: '1px solid rgb(255, 204, 180)', // Light orange border
        borderRadius: '8px', // Slightly rounded edges
        padding: '10px', // Padding inside the container
        marginTop: '0px', // Space between the question and the corresponding answer
    },
    answer: {
        fontFamily: 'Jancient, sans-serif',
        textAlign: 'left',
        margin: 0, // Remove default margin
    },
    header: {
        fontFamily: 'Jancient, sans-serif',
        textAlign: 'center',
        marginBottom: '10px', // Add spacing after the header
    },
};

const PrivacyPolicy: React.FC = () => {
    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: 4, // Material-UI spacing (4 = 4 * 8px = 32px)
                marginBottom: 4,
            }}
        >
            <Box>
                {/* Page Header */}
                <Typography
                    variant="h2"
                    gutterBottom
                    sx={textStyles.header}
                >
                    Privacy Policy
                </Typography>

                {/* Section 1 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Introduction</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        At American Polling Machine, LLC, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website, mobile applications, and services (collectively, the "Services").
                    </Typography>
                </Box>

                {/* Section 2 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Information We Collect</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        We may collect personal information that you voluntarily provide to us when you register for our service. The types of personal information we may collect include your email address, phone number, and zip code.
                    </Typography>
                </Box>

                {/* Section 3 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Use of Information</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        We use the information we collect to provide, maintain, and improve our Services, to communicate with you, and to personalize your experience. We may also use your information to send you updates, promotional materials, and other information that may be of interest to you.
                    </Typography>
                </Box>

                {/* Section 4 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Information Sharing</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        We do not share, sell, rent, or disclose your personal information to any third parties. Your information is kept strictly confidential and is only used in accordance with this Privacy Policy.
                    </Typography>
                </Box>

                {/* Section 5 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Data Security</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        We take the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet or method of electronic storage is completely secure.
                    </Typography>
                </Box>

                {/* Section 6 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Your Rights</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        You have the right to access, correct, update, or delete your personal information at any time. If you wish to exercise any of these rights, please contact us at support@letspoll.com.
                    </Typography>
                </Box>

                {/* Section 7 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Changes to This Privacy Policy</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the "Effective Date" at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
                    </Typography>
                </Box>

                {/* Section 8 */}
                <Typography variant="h5" gutterBottom sx={textStyles.question}>
                    <strong>Contact Us</strong>
                </Typography>
                <Box sx={textStyles.answerContainer}>
                    <Typography variant="body1" sx={textStyles.answer}>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    </Typography>
                    <Typography variant="body1" sx={textStyles.answer}>
                        American Polling Machine, LLC
                        <br />
                        support@letspoll.com
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
