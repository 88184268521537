const colors = {
    primary: '#1e90ff', // Main brand color
    background: '#ffffff', // Background color
    textPrimary: '#1e90ff', // Default text color
    textOnPrimary: '#ffffff', // Text color on primary backgrounds
    hoverBackground: '#1e90ff', // Hover background color
    hoverText: '#ffffff', // Hover text color
};

export default colors;
