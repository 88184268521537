import React, { useState } from 'react';
import {
    AppBar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Grid,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/APM_WHITE_FULL.png';
import colors from '../theme/colors'; // Import the color constants

const ApmAppBar: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path: string) => {
        navigate(path);
        handleMenuClose();
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                bgcolor: colors.primary,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                zIndex: 1201,
                padding: 2,
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        paddingLeft: 1,
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleMenuOpen}
                        sx={{ color: colors.textOnPrimary }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={logo}
                        alt="APM Logo"
                        sx={{
                            height: '125px',
                            width: 'auto',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                />
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: 1,
                    '& .MuiPaper-root': {
                        bgcolor: colors.background,
                        color: colors.textPrimary,
                    },
                }}
            >
                {location.pathname !== '/' && (
                    <MenuItem
                        onClick={() => handleNavigate('/')}
                        sx={{
                            '&:hover': {
                                bgcolor: colors.hoverBackground,
                                color: colors.hoverText,
                            },
                            '&:hover .MuiTypography-root': {
                                color: colors.hoverText, // Ensure Typography text also changes color
                            },
                            }}
                    >
                        <Typography>Home</Typography>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => handleNavigate('/about')}
                    sx={{
                        '&:hover': {
                            bgcolor: colors.hoverBackground, // Background color on hover
                            color: colors.hoverText, // Text color on hover
                        },
                        '&:hover .MuiTypography-root': {
                            color: colors.hoverText, // Ensure Typography text also changes color
                        },
                    }}
                >
                    <Typography>About</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigate('/privacy-policy')}
                    sx={{
                        '&:hover': {
                            bgcolor: colors.hoverBackground,
                            color: colors.hoverText,
                        },
                        '&:hover .MuiTypography-root': {
                            color: colors.hoverText, // Ensure Typography text also changes color
                        },
                    }}
                >
                    <Typography>Privacy Policy</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigate('/delete-account')}
                    sx={{
                        '&:hover': {
                            bgcolor: colors.hoverBackground,
                            color: colors.hoverText,
                        },
                        '&:hover .MuiTypography-root': {
                            color: colors.hoverText, // Ensure Typography text also changes color
                        },
                    }}
                >
                    <Typography>Delete Account</Typography>
                </MenuItem>
            </Menu>
        </AppBar>
    );
};

export default ApmAppBar;
